import React from "react";
import CommonProduct from "../components/commonPage"
import Button from '@mui/material/Button'
import { Link } from "gatsby"

class DecisionPage extends CommonProduct {
    constructor(props) {
        super(props)
        this.firstRender = true
    }

    render() {
        let BaseComponent = this.BaseComponentRender.bind(this)
        return (
            <BaseComponent controller={this} menu="decision" title="Decision Guide | EdTech Index" className="page">
            <h1>Decision Guide</h1>
            <div className="download_top">
            Download Blank Decision Guide and Rubric
            <Button className="template" onClick={()=>{window.open('https://docs.google.com/spreadsheets/d/1i5NRQGL6r8sFWFdyiBsXJY0p5UMQUe5CdAEg2nVht2M/edit#gid=591365272', '_blank');}}>
                Download
            </Button>
            </div>

            
            <p className="statement">
                There's no shortage of learning technology tools. In fact, there are more than 6,000 to choose from, and this number continues to grow. So, how do you choose what works best for you, your educational context and your students? It begins, as all journeys do, with a plan. Below, we share some helpful tips to start your journey and ideas on how to best narrow down your choices.
                When you are ready, you can <strong>export your short list from the EdSurge Index and consult our tailored <a href="https://docs.google.com/spreadsheets/d/1i5NRQGL6r8sFWFdyiBsXJY0p5UMQUe5CdAEg2nVht2M/edit#gid=591365272" rel="noreferrer" target="_blank">Decision Rubric</a> to help you compare and contrast tools</strong> to make a final choice that is right for you.
            </p>
            <h2>Our approach:</h2>
            <p className="approach">
                When looking for tools that might best align to your needs, consider the following 4 principles:
            </p>
            <ol className="top_level">
                <li className="inner"><div className="heading">Determine and align on goals</div>
                It's critical for administrators and instructors to build a shared understanding of the specific goals for teaching and learning in their class, school or institution. Too often, instructional coaches are given a directive such as: “Find a math tool to support the 8th grade teachers.” The problem is, there are hundreds of middle school math tools, and wading through them might not be the most effective or efficient method to discover the best product. Instead, whoever is charting the school's edtech path must start by asking about and understanding the specific problems of practice faced by the educator in that particular school. When considering a tool or digital resource, ask: 
                <ul>
                    <li>What instructional or operational problem are we trying to solve?</li>
                    <li>What outcomes or behaviors are we trying to change or improve?</li>
                    <li>What is or isn't working about our current solution?</li>
                </ul>
                </li>
                <li className="inner"><div className="heading">Understand what you have versus what you need</div>
                    <p>Even when educators have shared goals, there isn't a singular approach to teaching and learning to meet those outcomes. Authentic teaching looks different from one classroom to another, and understanding what works is an essential component in finding compatible technology solutions. Questions that can help identify specific needs include: </p>
                <ul>
                    <li>What teaching approaches have been the most successful in the past?</li>
                    <li>What are the pain points or problems of practice we are trying to solve for?</li>
                    <li>What types of technology might address these and help us move closer to our goals?</li>
                </ul>
                <p>Consider the possibility that not all teachers need the same tools—it's really about finding out what individuals need, not pushing a one-size-fits-all solution. A school's budget may not allow everyone to get each item they need or want at the same time, but spending scarce funds on a tool that won't be used is a double loss.</p>
                </li>
                <li className="inner">
                    <div className="heading">Find tools by fit not feature</div>
                    <p>
                    A bright and shiny tool makes compelling promises, but unless it aligns to the approaches and goals of the educators and students who will be using it, the tool will collect dust on the digital shelf. Educators and administrators may need to make compromises, and making a shared list of non-negotiable features is a must.
                    </p>
                    <p>
                    Non-negotiables are edtech product features that are required for proper implementation and that comply with school or district requirements. Aim for about 4 to 5 specific features that will set a tool apart.&nbsp;
                    <strong>See our <Link to="/validations/">Validations</Link> section for information about critical elements</strong> that should be considered at the beginning of any edtech selection process.
                    </p>
                </li>
                <li className="inner"><div className="heading">Free, freemium, premium — a note about cost </div>
                While cost is an important factor when choosing tools, it should not be listed in your non-negotiables up front. Determining price is difficult without the help of an edtech sales representative. It's also possible that funds will be available at the end of the search that weren't there at the beginning.
                Ultimately, the cost of an edtech product should help the team <strong>make the final decisions on a tool</strong>, not start the search. So, hold off a bit.
                </li>
            </ol>

            <h2>Disclaimer:</h2>
            <p className="disclaimer">
            This work is based on <a target="_blank" rel="noreferrer" href="https://www.edsurge.com/news/2017-09-24-the-secret-sauce-to-choosing-edtech-find-tools-by-fit-not-feature">The Secret Sauce to Choosing Edtech? Find Tools By Fit, Not Feature</a>, 
            an EdSurge article originally published in 2017, written by <a href="https://www.edsurge.com/writers/meg-hamel" target="_blank" rel="noreferrer">Meg Hamel</a>, Director of Learning Initiatives and ISTE and EdSurge Research.
            </p>

            </BaseComponent>
        )
    }
}
export default DecisionPage